<template>
  <div>
    <el-row class="row_line">
      <el-form :inline="true">
        <el-form-item label="Affiliate Id" label-width="100px">
          <el-input v-model="affiliateBlackId"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getBlackAffSourceList(1)">Search</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="delAffBlackList">Delete</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-form :inline="true" :model="affiliateblackMessage">
        <el-form-item label="AffiliateId" label-width="100px">
          <group-select
            v-model="affiliateblackMessage.affiliateIds"
            :data-source="affiliatesGroupList"
            multiple
            :loading="affiliateLoading"
            clearable
            reserve-keyword
            filterable
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="Description" label-width="100px">
          <el-input v-model="affiliateblackMessage.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addBlackAffSourceClick">Add</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      border
      stripe
      style="width:100%;"
      height="70vmin"
      highlight-current-row
      :data="affiliateblackMessageList"
      @selection-change="handleAffBlackSelectionChange"
    >
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column label="SourceId" prop="sourceId"></el-table-column>
      <el-table-column label="AffiliateId">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Affiliate Name">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Description" prop="description"></el-table-column>
      <el-table-column label="CreateTime" prop="createTime"></el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <el-button type="primary" @click="delaffiliateblackMessageList(scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="affiliateblacktotal"
      @handleSizeChange="handleAffiliateblackSizeChange"
      @handleCurrentChange="handleAffiliateblackCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 删除提示框 -->
    <el-dialog
      title="Delete"
      :visible.sync="delAffBlackVisible"
      width="300px"
      center
      append-to-body
    >
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delAffBlackVisible = false">cancel</el-button>
        <el-button type="primary" @click="delAffBlackRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { mapState, mapActions } from 'vuex';
  import { getAffBlacklist, addAffBlackList, deleteAffBlackList } from 'api/source/detail';
  let clickTimer = null;
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      id: {
        type: [String, Number],
        required: false,
      },
    },
    data() {
      return {
        affiliateBlackId: null,
        affiliateblackMessageList: [],
        affiliateblacktotal: null,
        pageSize: 10,
        currentPage: 1,
        affiliateblackMessage: {},
        delAffBlackVisible: false,
        delArr: [],
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
      this.getBlackAffSourceList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      getBlackAffSourceList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          sourceId: this.id,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.affiliateBlackId) {
          param['affiliateIds'] = this.affiliateBlackId;
        }
        getAffBlacklist(param).then((response) => {
          if (response.code === 200) {
            this.affiliateblackMessageList = response.result;
            this.affiliateblacktotal = response.total;
          }
        });
      },
      addBlackAffSourceClick() {
        let param = {
          ...this.affiliateblackMessage,
          sourceId: this.id,
        };
        addAffBlackList(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getBlackAffSourceList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      delAffBlackList() {
        this.delAffBlackVisible = true;
        const length = this.affBlackSelectionList.length;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.affBlackSelectionList[i].id);
        }
      },
      delaffiliateblackMessageList(row) {
        this.delAffBlackVisible = true;
        this.delArr.push(row.id);
      },
      delAffBlackRow() {
        let param = {
          sourceId: this.id,
          ids: this.delArr.join(','),
        };
        deleteAffBlackList(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getBlackAffSourceList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delArr = [];
        this.delAffBlackVisible = false;
      },
      handleAffBlackSelectionChange(val) {
        this.affBlackSelectionList = val;
      },
      handleAffiliateblackSizeChange(val) {
        this.pageSize = val;
        this.handleAffiliateblackCurrentChange(1);
      },
      handleAffiliateblackCurrentChange(val) {
        this.currentPage = val;
        this.getBlackAffSourceList();
      },
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            that.$router.push({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId,
              },
            });
          });
        }, 300);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row_line {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
