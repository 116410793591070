<template>
  <div>
    <el-row class="row_line">
      <el-form :inline="true">
        <el-form-item label="Affiliate Id" label-width="100px">
          <group-select
            v-model="filter.affiliateId"
            :data-source="affiliatesGroupList"
            :loading="affiliateLoading"
            clearable
            reserve-keyword
            filterable
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="Affiliate Subs">
          <el-input v-model="filter.affSubs" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getBlackAffSubList(1)">Search</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="delBatchBlackAffSub">Delete</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-form :inline="true" :model="blackMessage">
        <el-form-item label="AffiliateId" label-width="100px">
          <group-select
            v-model="blackMessage.affiliateIds"
            :data-source="affiliatesGroupList"
            multiple
            :loading="affiliateLoading"
            clearable
            reserve-keyword
            filterable
            hasAll
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="AffSub">
          <el-input v-model="blackMessage.affSubs"></el-input>
        </el-form-item>
        <el-form-item label="Description" label-width="100px">
          <el-input v-model="blackMessage.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addBlackAffSubClick">Add</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      border
      stripe
      style="width:100%;"
      height="70vmin"
      @selection-change="handleblackAffSubSelectionChange"
      :data="blackMessageList"
      highlight-current-row
    >
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId" align="center"></el-table-column>
      <el-table-column label="Affiliate Name" prop="affiliateName" align="center"></el-table-column>
      <el-table-column label="AffSub" prop="affSub" align="center"></el-table-column>
      <el-table-column label="Description" prop="description" align="center"></el-table-column>
      <el-table-column
        label="CreateTime"
        prop="createTime"
        align="center"
        min-width="120"
      ></el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <el-button type="primary" @click="delblackAffSubSourceClick(scope.row)">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="blackAffSubtotal"
      @handleSizeChange="handleblackAffSubSizeChange"
      @handleCurrentChange="handleblackAffSubCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 删除提示框 -->
    <el-dialog
      title="Delete"
      :visible.sync="delblackAffSubVisible"
      width="300px"
      center
      append-to-body
    >
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delblackAffSubVisible = false">cancel</el-button>
        <el-button type="primary" @click="delblackAffSubRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { mapState, mapActions } from 'vuex';
  import { getAffSubBlacklist, addAffSubBlackList, deleteAffSubBlackList } from 'api/source/detail';
  import { filterObject } from 'utils/utils';
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {},
        blackAffSubtotal: null,
        pageSize: 10,
        currentPage: 1,
        blackMessage: {},
        blackMessageList: [],
        delblackAffSubVisible: false,
        delArr: [],
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
      this.getBlackAffSubList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      getBlackAffSubList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          ...filterObject(this.filter),
          sourceId: this.id,
          page: this.currentPage,
          pageSize: this.pageSize,
        };

        getAffSubBlacklist(param).then((response) => {
          if (response.code == 200) {
            this.blackMessageList = response.result;
            this.blackAffSubtotal = response.total;
          } else {
            this.blackMessageList = [];
            this.blackAffSubtotal = null;
          }
        });
      },
      delblackAffSubSourceClick(row) {
        this.delblackAffSubVisible = true;
        this.delArr.push(row.id);
      },

      addBlackAffSubClick() {
        let param = {
          ...this.blackMessage,
          sourceId: this.id,
        };
        if (param.affiliateIds.length === 0 || !param.affSubs || !param.desc == null) {
          this.$message.error('input should not be empty~');
          return;
        }

        param.affSubs = param.affSubs.split(',');
        addAffSubBlackList(param).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getBlackAffSubList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      delblackAffSubRow() {
        let param = {
          sourceId: this.id,
          ids: this.delArr.join(','),
        };

        deleteAffSubBlackList(param).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getBlackAffSubList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delArr = [];
        this.delblackAffSubVisible = false;
      },
      delBatchBlackAffSub() {
        this.delblackAffSubVisible = true;
        const length = this.blackAffSubSelectionList.length;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.blackAffSubSelectionList[i].id);
        }
      },
      handleblackAffSubSizeChange(val) {
        this.pageSize = val;
        this.handleblackAffSubCurrentChange(1);
      },
      handleblackAffSubCurrentChange(val) {
        this.currentPage = val;
        this.getBlackAffSubList();
      },
      handleblackAffSubSelectionChange(val) {
        this.blackAffSubSelectionList = val;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row_line {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
</style>
