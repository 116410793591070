<template>
  <div>
    <el-row class="row_line">
      <el-form :inline="true">
        <el-form-item label="Affiliate Id" label-width="100px">
          <el-input v-model="filter.affiliateId"></el-input>
        </el-form-item>
        <el-form-item label="AffSub" label-width="100px">
          <el-input v-model="filter.affSub"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getAffSubWhiteList(1)">Search</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="delAffSubWhiteList">Delete</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-form :inline="true" :model="affSubWhite">
        <el-form-item label="Type" label-width="80px">
          <el-select v-model="affSubWhite.type">
            <el-option
              v-for="item in typeOption"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="AffiliateId" label-width="100px">
          <group-select
            v-model="affSubWhite.affiliateIds"
            :data-source="affiliatesGroupList"
            multiple
            :loading="affiliateLoading"
            reserve-keyword
            clearable
            filterable
            hasAll
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="AffSubs">
          <el-input v-model="affSubWhite.affSubs"></el-input>
        </el-form-item>
        <el-form-item label="Description" label-width="100px">
          <el-input v-model="affSubWhite.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addAffSubWhiteClick">Add</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      border
      stripe
      style="width:100%;"
      @selection-change="handleAffWhiteSelectionChange"
      height="70vmin"
      :data="affSubWhiteList"
      highlight-current-row
    >
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId" align="center"></el-table-column>
      <el-table-column label="Affiliate Name" prop="affiliateName" align="center"></el-table-column>
      <el-table-column label="AffSub" prop="affSub" align="center"></el-table-column>
      <el-table-column label="Type" prop="type" align="center"></el-table-column>
      <el-table-column label="Description" prop="description" align="center"></el-table-column>
      <el-table-column label="CreateTime" prop="createTime" align="center"></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="affSubWhitetotal"
      @handleSizeChange="handleaffSubWhiteSizeChange"
      @handleCurrentChange="handleaffSubWhiteCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import { mapState, mapActions } from 'vuex';
  import { getAffSubWhiteList, addAffSubWhiteList, deleteAffSubWhiteList } from 'api/source/detail';
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {},

        affSubWhite: {
          affiliateIds: null,
          affSubs: null,
          description: null,
        },
        affSubWhiteList: [],
        typeOption: [
          {
            label: 'IN-AFF(Other Aff Can Run)',
            value: 'IN-AFF',
          },
          {
            label: 'IN-ALL(Other Aff Can Not Run)',
            value: 'IN-ALL',
          },
        ],
        collaffSubWhiteFlag: false,
        affSubWhitetotal: 0,
        pageSize: 10,
        currentPage: 1,
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffSubWhiteList();
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      getAffSubWhiteList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          ...this.filter,
          sourceId: this.id,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getAffSubWhiteList(param).then((response) => {
          if (response.code === 200) {
            this.affSubWhiteList = response.result;
            this.affSubWhitetotal = response.total;
          } else {
            this.affSubWhiteList = [];
            this.affSubWhitetotal = null;
          }
        });
      },
      delAffSubWhiteList() {
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            const length = this.affSubSourceWhiteSeletionList.length;
            console.log(this.affSubSourceWhiteSeletionList);
            for (let i = 0; i < length; i++) {
              this.delArr.push(this.affSubSourceWhiteSeletionList[i].id);
            }
            let param = {
              sourceId: this.id,
              ids: this.delArr.join(','),
            };

            deleteAffSubWhiteList(param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Delete Success',
                  type: 'success',
                });
                this.getAffSubWhiteList();
              } else {
                this.$message.error('Delete Error:' + response.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
        this.delArr = [];
      },
      addAffSubWhiteClick() {
        const param = {
          ...this.affSubWhite,
          sourceId: this.id,
        };
        console.log(this.id);
        //支持按','分割多个affSubs
        if (param.affSubs) {
          param.affSubs = param.affSubs.split(',');
        }
        console.log(param);
        addAffSubWhiteList(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Update',
              type: 'success',
            });
            this.getAffSubWhiteList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      handleAffWhiteSelectionChange(val) {
        this.affSubSourceWhiteSeletionList = val;
      },
      handleaffSubWhiteSizeChange(val) {
        this.pageSize = val;
        this.handleaffSubWhiteCurrentChange(1);
      },
      handleaffSubWhiteCurrentChange(val) {
        this.currentPage = val;
        this.getAffSubWhiteList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row_line {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
</style>
