<template>
  <div>
    <el-row class="row_line">
      <el-form :inline="true">
        <el-form-item label="Affiliate Id" label-width="100px">
          <el-input v-model="affiliateWhiteIds"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getAffiliateWhiteList(1)">Search</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="delAffiliateList">Delete</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-form :inline="true" :model="affiliateWhite">
        <el-form-item label="AffiliateId" label-width="100px">
          <el-select
            v-model="affiliateWhite.affiliateIds"
            multiple
            :loading="affiliateLoading"
            clearable
            reserve-keyword
            filterable
            placeholder="请选择"
          >
            <el-option-group
              v-for="group in affiliatesGroupList"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                v-for="item in group.children"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="affiliateWhite.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addAffiliateWhiteList">Add</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      border
      stripe
      @selection-change="handleAffiliateWhiteSelectionChange"
      style="width:100%;"
      height="70vmin"
      :data="affiliateWhiteList"
      highlight-current-row
    >
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column label="Id" prop="id"></el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId"></el-table-column>
      <el-table-column label="Affiliate Name" prop="affiliateName"></el-table-column>
      <el-table-column label="Sub Affiliate Name" prop="affsub"></el-table-column>
      <el-table-column label="Description" prop="description"></el-table-column>
      <el-table-column label="CreateTime" prop="createTime"></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="affiliateWhitetotal"
      @handleSizeChange="handleaffiliateWhiteSizeChange"
      @handleCurrentChange="handleaffiliateWhiteCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 删除提示框 -->
    <el-dialog
      title="Delete"
      :visible.sync="delaffiliateWhiteVisible"
      width="300px"
      center
      append-to-body
    >
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delaffiliateWhiteVisible = false">cancel</el-button>
        <el-button type="primary" @click="delaffiliateWhiteRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import { getAffWhiteList, addAffWhiteList, deleteAffWhiteList } from 'api/source/detail';
  import { mapState, mapActions } from 'vuex';
  export default {
    components: {
      Pagination,
    },
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        collFlag: false,
        affiliateWhiteList: [],
        affiliateWhiteIds: null,
        affiliateWhite: {
          affiliateIds: null,
          description: null,
        },
        affiliateId: null,
        affiliateWhitetotal: null,
        pageSize: 10,
        currentPage: 1,
        delaffiliateWhiteVisible: false,
        affiliateWhiteSelectionList: [],
        delArr: [],
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
      this.getAffiliateWhiteList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),

      getAffiliateWhiteList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        let param = {
          sourceId: this.id,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.affiliateWhiteIds) {
          param['affiliateIds'] = this.affiliateWhiteIds;
        }
        getAffWhiteList(param).then((response) => {
          if (response.code === 200) {
            this.affiliateWhiteList = response.result;
            this.affiliateWhitetotal = response.total;
          } else {
            this.affiliateWhiteList = [];
            this.affiliateWhitetotal = null;
          }
        });
      },
      delAffiliateList() {
        const length = this.affiliateWhiteSelectionList.length;
        if (length === 0) {
          this.$message.warning('你还没有选择要删除的数据');
          return;
        }
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.affiliateWhiteSelectionList[i].id);
        }
        this.delaffiliateWhiteVisible = true;
      },
      addAffiliateWhiteList() {
        let param = {
          ...this.affiliateWhite,
        };

        if (param.description == null || param.affiliateIds.length == 0) {
          this.$message.error('input should not be empty~');
          return;
        }
        param['sourceId'] = this.id;
        console.log(param);
        addAffWhiteList(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getAffiliateWhiteList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      handleAffiliateWhiteSelectionChange(val) {
        this.affiliateWhiteSelectionList = val;
      },
      handleaffiliateWhiteSizeChange(val) {
        this.pageSize = val;
        this.handleaffiliateWhiteCurrentChange(1);
      },
      handleaffiliateWhiteCurrentChange(val) {
        this.currentPage = val;
        this.getAffiliateWhiteList();
      },
      delaffiliateWhiteRow() {
        let param = {
          ids: this.delArr.join(),
          sourceId: this.id,
        };
        deleteAffWhiteList(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getAffiliateWhiteList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delArr = [];
        this.delaffiliateWhiteVisible = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row_line {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
</style>
