<template>
  <div>
    <el-form :inline="true">
      <el-form-item label="Affiliate Id" label-width="100px">
        <el-input v-model="affiliateAccessId"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getAffList(1)">Search</el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      stripe
      style="width:100%;"
      height="60vmin"
      :data="affList"
      highlight-current-row
    >
      <el-table-column label="AffiliateId" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Affiliate Name" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="payoutType" prop="payoutType" align="center"></el-table-column>
      <el-table-column label="isInBlacklist" prop="isInBlacklist" align="center"></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="accesstotal"
      @handleSizeChange="handleAccessSizeChange"
      @handleCurrentChange="handleAccessCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>

<script>
  import { getAffAllowList } from 'api/source/detail';
  import Pagination from '@/components/pagination';

  let clickTimer = null;
  export default {
    components: {
      Pagination,
    },
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        affiliateAccessId: '',
        accesstotal: 0,
        pageSize: 10,
        currentPage: 1,
        affList: [],
      };
    },
    mounted() {
      this.getAffList();
    },
    methods: {
      getAffList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          sourceId: this.id,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.affiliateAccessId) {
          param['affiliateIds'] = this.affiliateAccessId;
        }
        getAffAllowList(param).then((response) => {
          if (response.code === 200) {
            this.affList = response.result;
            this.accesstotal = response.total;
          }
        });
      },
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            that.$router.push({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId,
              },
            });
          });
        }, 300);
      },
      handleAccessSizeChange(val) {
        this.pageSize = val;
        this.handleAccessCurrentChange(1);
      },
      handleAccessCurrentChange(val) {
        this.currentPage = val;
        this.getAffList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
