<template>
  <div>
    <el-form :inline="true" :model="adjustRateSetting">
      <el-form-item label="Rate" label-width="60px">
        <el-input v-model="adjustRateSetting.rate"></el-input>
      </el-form-item>
      <el-form-item label="Description" label-width="100px">
        <el-input v-model="adjustRateSetting.description"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addAdjustRateList">Add</el-button>
        <el-button type="primary" @click="deladjustRateList()">Delete</el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      stripe
      :data="adjustRateList"
      highlight-current-row
      style="width:100%;"
      height="70vmin"
      @selection-change="handleSelectionChange"
    >
      >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="SourceId" prop="sourceId"></el-table-column>
      <el-table-column label="Rate" prop="rate"></el-table-column>
      <el-table-column label="Description" prop="description"></el-table-column>
      <el-table-column label="CreateTime" prop="createTime"></el-table-column>
    </el-table>
    <!-- 删除提示框 -->
    <el-dialog
      title="Delete"
      :visible.sync="deladjustRateVisible"
      width="300px"
      center
      append-to-body
    >
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deladjustRateVisible = false">cancel</el-button>
        <el-button type="primary" @click="delAdjustRateRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAdjustRateList, addAdjustRate, deleteAdjustRate } from 'api/source/detail';
  export default {
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        adjustRateSetting: {
          rate: null,
          description: null,
        },
        adjustRateList: [],
        delArray: [],
        deladjustRateVisible: false,
      };
    },
    mounted() {
      this.getSourceList();
    },
    methods: {
      getSourceList() {
        getAdjustRateList(this.id).then((response) => {
          if (response.code === 200) {
            this.adjustRateList = response.result;
          }
        });
      },
      addAdjustRateList() {
        const param = {
          ...this.adjustRateSetting,
          sourceId: this.id,
        };
        addAdjustRate(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getSourceList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      deladjustRateList() {
        if (this.delArray.length === 0) {
          this.$message.warning('你还没有选择要删除的数据');
          return;
        }
        this.deladjustRateVisible = true;
      },
      delAdjustRateRow() {
        const param = {
          ids: this.delArray.join(','),
          sourceId: this.id,
        };
        deleteAdjustRate(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getSourceList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delId = null;
        this.deladjustRateVisible = false;
      },
      handleSelectionChange(val) {
        this.delArray = val.map((item) => item.id);
      },
    },
  };
</script>

<style></style>
